<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-dept
            :editable="editable"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <c-func-location 
            type="search" 
            :plantCd="searchParam.plantCd" 
            :editable="editable" 
            label="기능위치" 
            name="funcLocationCd" 
            v-model="searchParam.funcLocationCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <c-equip-class
            :editable="editable"
            label="설비유형"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <c-select
            type="search"
            codeGroupCd="WO_EQUIP_STOP_CD"
            itemText="codeName"
            itemValue="code"
            name="discardFlag"
            label="설비상태"
            v-model="searchParam.discardFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus()]">
                    {{ props.row.equipmentTypeName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus2()]">
                    {{ props.row.managementDeptName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.equipmentName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.discardFlagName }} | {{ props.row.funcLocationName }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-manager',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        funcLocationCd: '',
        equipmentName: '',
        equipmentTypeCd: '',
        grade: null,
        discardFlag: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      discardFlagItems: [
        { code: 'N', codeName: '가동중' },
        { code: 'Y', codeName: '고장' },
        { code: 'X', codeName: '불용' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'discardFlag',
            field: 'discardFlag',
            label: '설비상태',
            align: 'center',
            type: 'custom',
            sortable: true,
            style: 'width: 140px',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          // {
          //   name: 'sparepart',
          //   field: 'sparepart',
          //   label: 'spare part',
          //   align: 'center',
          //   type: 'custom',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            sortable: true,
            style: 'width: 250px',
          },
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   label: '단위공정',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 200px',
          // },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'funcLocationName',
            field: 'funcLocationName',
            label: '기능위치',
            align: 'center',
            sortable: true,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'equipGradeName',
          //   field: 'equipGradeName',
          //   label: '설비등급',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      findEquipUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.equipment.list.url;
      this.findEquipUrl = selectConfig.mdm.equipment.get.url;
      if (this.$route.query.equipmentCd) {
        this.getQrDetail();
      }
      this.getList();
    },
    getColorStatus() {
      return 'txt-box-grid text-primary-box';
    },
    getColorStatus2() {
      return 'txt-box-grid text-orange-box';
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(evt, row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        plantCd: row ? row.plantCd : '',
        tabName: row.tabName ? row.tabName : 'equipmentInfo',
      };
      this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    getQrDetail() {
      this.$http.url = this.findEquipUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        equipmentCd: this.$route.query.equipmentCd,
        plantCd: this.$route.query.plantCd,
      };
      this.$http.request((_result) => {
        if (_result.data) {
          this.popupOptions.title = '설비 상세';
          this.popupOptions.param = {
            equipmentCd: this.$route.query.equipmentCd,
            plantCd: this.$route.query.plantCd,
            tabName: this.$route.query.tabName,
          };
          this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
          this.popupOptions.visible = true;
          this.popupOptions.isFull = true;
          this.popupOptions.closeCallback = this.closePopup;
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '존재하지 않는 설비입니다.',
            type: 'warning', // success / info / warning / error
          });
        }
      },); 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
